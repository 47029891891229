<template>
  <div class="game-header">
    <!-- left -->
    <div class="geme-header__left">
      <div class="game-header__player">
        <div
          class="game-header__player-avatar"
          :style="{
            marginLeft: '10px',
            backgroundImage: `url('/images/game/user.png')`
          }"
        />
        <div
          v-if="$store.state.ui.width > 768"
          class="game-header__player-name flex flex-row align-center content-center"
          :style="{
            height: '32px',
          }"
        >
          {{ user.name }}
        </div>
      </div>
    </div>
    <!-- levels -->
    <div
      v-if="gameInfo.current_level !== 'final'"
      v-show="gameInfo.current_level !== undefined"
      class="game-header__center"
      :style="{
        paddingTop: ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level) ? '24px' : '0px',
        pointerEvents: 'none',
        userSelect: 'none',
      }">
      <div
        v-if="['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)"
        class="flex flex-row flex-wrap w-full justify-center"
        :style="{
          paddingTop: '10px',
          paddingBottom: '10px',
        }"
      >
        <!-- boss health -->
        <div
          v-for="h in 3" :key="h.id" class="health-bar">
          <div
            :ref="`h-${h}`"
            class="health-bar-value"
            :style="{
              height: '30px',
            }"
          />
        </div>
        <!-- boss name -->
        <div class="flex flow-row flex-wrap w-full justify-center">
          <span
            :style="{
              color: 'white',
              fontSize: $store.state.ui.width > 768 ? '18px' : '20px',
              fontWeight: 'bold',
              fontFamily: 'BlenderPro Thin',
              marginTop: $store.state.ui.width > 768 ? '' : '-10px',
            }"
          > {{ levelMap.name }} </span>
        </div>
      </div>
      <div v-else class="game-header__stage-container">
        <div
          v-for="(l, lkey, li) in levels"
          :key="lkey"
          class="game-header__stage-item"
          :class="{ current: lkey === gameInfo.current_level }"
        >
          {{ $store.state.ui.width > 768 ? l.name : li+1 }}
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="geme-header__right">
      <div class="game-header__control-container">
        <button
          class="game-header__control-item"
          :style="{
            backgroundImage: soundToggleBackgroundImage,
            backgroundColor: $store.state.ui.soundOn ? '#ffef40 !important' : '#131120 !important',
          }"
          @click="soundToggle()"
        />
        <button
          class="game-header__control-item"
          style="background-image: url('/images/icons/fi_menu_32px.svg')"
          @click="menuToggle()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsModal from '@/components/SettingsModal';

export default {
  name: 'GameHeader',
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level];
    },
    user () {
      return this.$store.getters.user;
    },
    levels () {
      const levels = {};
      levels.q_1 = this.$store.state.game.gameInfoMap.levels.c_1;
      levels.q_2 = this.$store.state.game.gameInfoMap.levels.c_2;
      levels.q_3 = this.$store.state.game.gameInfoMap.levels.c_3;
      return levels;
    },
    health () {
      return this.gameInfo.boss_health;
    },
    soundToggleBackgroundImage () {
      if (this.$store.state.ui.soundOn) return "url('/images/icons/volume_on_32px.svg')"
      else return "url('/images/icons/volume_off_32px.svg')"
    }
  },
  watch: {
    health: {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          if (from > 0) {
            const el = this.$refs['h-'+from][0]
            this.$log(to, from, el, 'HEALTH')
            this.$tween.set(el, { maxWidth: '100%' })
            this.$tween.to(el, 1, { maxWidth: '0' })
          }
        })
      }
    },
  },
  methods: {
    barShow () {
      this.$log('barShow')
      const bar = this.$refs.bar;
      if (!bar) return
      this.$tween.set(bar, { y: 200, transform: 'scale(0.5)', opacity: '0' });
      this.$tween.to(bar, 1, { y: 0, transform: 'scale(1)', opacity: '1' })
    },
    soundToggle () {
      this.$log('soundToggle')
      this.$store.commit('ui/SET_STATE_PROP', ['soundOn', !this.$store.state.ui.soundOn])
    },
    menuToggle() {
      this.$log('menuToggle')
      if (this.menuOpened) {
        this.$modal.hide('settings-modal')
      }
      else {
        this.$modal.show(
          SettingsModal,
          {},
          {
            name: 'settings-modal',
            classes: 'settings-modal',
            height: 'auto',
            width: this.$store.state.ui.width > 768 ? '700px' : '100%',
            clickToClose: true,
            scrollable: true,
          },
          {
            'before-open': () => {},
            'before-close': () => {},
          },
        );
      }
      this.menuOpened = !this.menuOpened
    },
  },
  async mounted () {
    this.$log('mounted')
    this.$nextTick(() => {
      this.barShow()
    })
  },
};
</script>

<style lang="scss" scoped>
.heart {
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0;
  background: url('/images/game/heart.svg');
  background-size: 100%;
}
.health-container {
  position: absolute;
  right: 0;
  display: flex;
  top: -10%;
  width: 100%;
  top: 0;
  margin-top: 10px;
  flex-direction: row;
  align-items: flex-start;
  left: calc(50% - 150px);
}
.health-bar {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #131120;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #2c2b33;
  font-family: 'BlenderPro';
  height: 25px;
  width: 150px;
  padding: 1.5px;
  border: 1px solid #EA3344;
  --clip-size: 20px;
  --border-color: #EA3344;
  &:after {
    position: absolute;
    content: '';
    border-left: 25px solid var(--border-color);
    border-top: 25px solid transparent;
    transform: rotate(6deg);
    top: -1px;
    left: -2px;
    opacity: 0.9;
  }
  &:before {
    position: absolute;
    content: '';
    border-top: 25px solid var(--border-color);
    border-left: 25px solid transparent;
    transform: rotate(6deg);
    top: 0;
    right: -2px;
    opacity: 0.9;
  }
  clip-path: polygon(
    calc(100% - var(--clip-size)) 0%,
    100% 100%,
    calc(0% + var(--clip-size)) 100%,
    0% 0%);
  &-value {
    position: relative;
    display: flex;
    align-items: center;
    background: #EA3344;
    max-height: 20px !important;
    left: 0;
    width: 100%;
    margin: 0px 3px;
    clip-path: polygon(
    calc(100% - 16px) 0%,
    100% 100%,
    calc(0% + 16px) 100%,
    0% 0%);
  }
}
.game-header {
  max-width: 1280px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 0 0px;
  box-sizing: border-box;
  position: absolute; 
  z-index: 99; 
  top: 0;
  &__player {
    &-avatar {
      background-color: #131120;
      --size: 32px;
      width: var(--size);
      height: var(--size);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      --clip-size: 7px;
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--clip-size)),
        calc(100% - var(--clip-size)) 100%,
        0 100%
      );
    }
    &-name {
      display: inline-block;
      font-family: 'BlenderPro Bold';
      padding-top: 7px;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 1px 1px 0px #000000;
      vertical-align: middle;
    }
  }
  &__stage {
    &-container {
      margin: 0 -2px;
      display: flex;
      flex-wrap: wrap;
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 40px;
      background: #131120;
      box-sizing: border-box;
      margin: 5px 2px;
      margin-top: 20px;
      padding: 0 8px;
      font-size: 18px;
      line-height: 100%;
      color: #7f7e8a;
      font-family: 'BlenderPro';
      line-height: 30px;
      text-align: center;
      --clip-size: 7px;
      --border-color: #2e2c3f;
      border: 1.5px solid var(--border-color);
      position: relative;
      &:before {
        position: absolute;
        content: '';
        border-bottom: var(--clip-size) solid var(--border-color);
        border-left: var(--clip-size) solid transparent;
        bottom: 0;
        right: 0;
        opacity: 0.9;
      }
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--clip-size)),
        calc(100% - var(--clip-size)) 100%,
        0 100%
      );
      &.current {
        width: auto;
        padding: 0 13px 0 14px;
        --border-color: #40ff90;
        color: #40ff90;
      }
    }
  }
  &__control {
    &-container {
      margin: 0 0px;
    }
    &-item {
      display: inline-block;
      border: none;
      margin: 0 3px;
      --size: 32px;
      width: var(--size);
      height: var(--size);
      background: #131120;
      --clip-size: 4px;
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--clip-size)),
        calc(100% - var(--clip-size)) 100%,
        0 100%
      );
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        background-color: var(--secondary);
      }
    }
  }
}

@media (max-width: 768px) {
  .health-bar {
    width: 60px;
    height: 10px;
    margin-left: -10px; 
    margin-top: -20px;
  }
  .health-container {
    left: 0;
  }
  .game-header {
    &__player {
      &-avatar {
        --size: 26px;
      }
      &-name {
        font-size: 13px;
      }
    }
    &__stage {
      &-container {
        display: none;
      }
    }
    &__control {
      &-item {
        --size: 26px;
        background-size: 12px;
      }
    }
  }
}
</style>
